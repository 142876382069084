import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../app/store/configureStore";
import { signInUserSSO } from "./accountSlice";
import LoadingSpinner from "../../app/common/LoadingSpinner";

interface UserDto {
  id: string;
  username: string;
  token: string; // Raw token
  role: string;
  welcomeComplete: boolean;
  email: string;
  firstName: string;
  lastName: string;
  credentialName?: string | null; // Nullable
  credentialTypeId?: number | null; // Nullable
  cdaCouncilCandidateId?: number | null; // Nullable
  languageSpecialization?: string | null; // Nullable
  portfolioId?: number | null; // Nullable
  homeState?: string | null; // Nullable
  phoneNumber?: string | null; // Nullable
  homeZipCode?: string | null; // Nullable
  isActive: boolean;
  isSpanish?: boolean;
}

const LoginSSO: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

console.log("LoginSSO component rendered");

  // Parse the query string to retrieve the token
  const params = new URLSearchParams(location.search);

  const userString = params.get("user") ?? "";
  console.log(`userString: ${userString}`);

  const role = params.get("role") ?? "";
  const encodedRole = encodeURIComponent(role);
  console.log(`role: ${role}`);

  useEffect(() => {
    let userDto: UserDto | null = null;

    // Try retrieving the user from the query string or session storage
    if (userString.length > 0) {
      try {
        console.log(`User string begin JSON.parse: ${userString}`);
        userDto = JSON.parse(userString);
        console.log(`User parsed: ${JSON.stringify(userDto)}`);
      } catch (error) {
        console.error("Error parsing userString:", error);
      }
    }

    // Redirect if role and user email are missing
    if (!role && !userDto) {
      console.log("Role and userDto is missing, redirecting to Idp");
      console.log(`The Role is: ${role} and the UserDto is: ${userDto}`);

      window.location.href = "https://cfprportal-uat.azurewebsites.net/en-US/";
    }

    const handleToken = async () => {
      if (userDto) {
        console.log("Dispatching UserDto signInUserSSO");
        await dispatch(signInUserSSO(userDto));
        console.log("UserDto dispatched to signInUserSSO");
        navigateUser(userDto); // Assuming navigateUser handles the userDto object
      } else {
        console.log(
          "User object not provided, starting to request SSO from Idp"
        );

        // Redirect to SSO URL based on environment
        if (window.location.href.includes("localhost")) {
          window.location.href = `https://localhost:5000/api/Saml2/sso?role=${encodedRole}`;
        } else if (window.location.href.includes("dev.")) {
          window.location.href = `https://dev.api.yourcdaportfolio.org/api/Saml2/sso?role=${encodedRole}`;
        } else if (window.location.href.includes("test.")) {
          window.location.href = `https://test.api.yourcdaportfolio.org/api/Saml2/sso?role=${encodedRole}`;
        } else {
          window.location.href = `https://api.yourcdaportfolio.org/api/Saml2/sso?role=${encodedRole}`;
        }
      }
    };

    handleToken(); // Execute the token handler
  }, [userString, role, dispatch, navigate]);
  // Function to handle navigation based on user role
  const navigateUser = (u: UserDto) => {
    console.log("Navigating user:", u);
    const admin = u.role === "CSI Admin" || u.role === "Council Admin";
    const trainer = u.role === "Trainer";
    const otherViewer = u.role === "Other Viewer";

    if (admin) {
      // Only for admins
      navigate("/admin/dashboard");
    } else if (!admin && !trainer && !otherViewer && u.welcomeComplete) {
      // Only if welcomeComplete is true
      navigate("/myprogresssummary");
    } else if (!admin && !trainer && !otherViewer && !u.welcomeComplete) {
      // Only if welcomeComplete is false
      navigate("/welcome");
    } else if (trainer) {
      // Only for traainers
      navigate("/trainer/students");
    } else if (otherViewer) {
      // Only for other viewers
      navigate("/editprofile");
    } else {
      // Default case and Manage Users
      navigate("/trainer/manageusers");
    }
  };

  return <LoadingSpinner />;
};

export default LoginSSO;
